<template>
  <VDialog
    :value="value"
    max-width="360"
    @input="$emit('input', false)"
  >
    <div class="p-relative">
      <TTBtn
        icon
        x-small
        color="tt-dark"
        class="close-button"
        data-test-label="buttonClose"
        @click="$emit('input', false)"
      >
        <VIcon size="19">
          fal fa-times
        </VIcon>
      </TTBtn>
    </div>
    <VCard class="pa-3">
      <VImg
        :src="require('@/assets/img/RobotGpt2.png')"
        height="128"
        width="341"
        contain
      />
      <div class="tt-text-title-1 py-3">
        {{ $t('yandexGPT.hintHeader') }}
      </div>
      <div class="tt-text-body-2">
        {{ $t('yandexGPT.hintBody') }}
      </div>
    </VCard>
  </VDialog>
</template>

<script>

export default {
  name: 'RobotGptModalHint',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.close-button {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
}
</style>
