
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TsxassAbility, LicenseType } from '@/helpers/constants';
import i18n from '@/plugins/vue-i18n';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import { V1EntitiesExpertListsPublicAnalyticsIndexExpertList } from '@/services/api/tsxass';

const allGroupsItem = {
  key: null,
  id: null,
  name: i18n.t('analytics.allExpertGroups') as string,
  withoutSelf: false,
};
const withoutSelfItem = {
  key: -2,
  id: null,
  name: i18n.t('analytics.allExpertGroupsWithoutSelf') as string,
  withoutSelf: true,
};

interface GroupSelectItem {
  id: number | null;
  name: string;
  withoutSelf: boolean;
}

export default Vue.extend({
  name: 'SelectExpertsGroup',

  props: {
    value: {
      type: [Object, Number, null] as PropType<GroupSelectItem | Number | null>,
      default: null,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    withoutSelfassVisible: {
      type: Boolean,
      default: false,
    },
    selfassVisible: {
      type: Boolean,
      default: true,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
    hideByLicense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      groups: null as V1EntitiesExpertListsPublicAnalyticsIndexExpertList[] | null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('profile', {
      profile: 'profile',
      licenses: 'licenses',
    }),
    hasHideLicense(): Boolean {
      const { profile } = this;
      const hasHideLicense = this.licenses.some((name: string) => name === LicenseType.HIDE_EXPERT_GROUPS);
      const canEditSurvey = this.can(TsxassAbility.CAN_EDIT_ANY_SURVEY_360);
      return (this.hideByLicense && hasHideLicense && !canEditSurvey) || !profile;
    },
    groupSelectItems(): GroupSelectItem[] {
      const mappedGroups = (this.groups || []).map((group) => ({
        ...group,
        key: group.id,
        withoutSelf: false,
      }));
      if (this.withoutSelfassVisible) {
        return [allGroupsItem, withoutSelfItem, ...mappedGroups];
      }
      return [allGroupsItem, ...mappedGroups];
    },
  },

  mounted() {
    this.loadGroups();
  },

  methods: {
    async loadGroups() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsSurveysSurveyIdExpertsLists(
          this.surveyId,
          this.userId || undefined,
          {
            withoutSelf: this.withoutSelfassVisible,
          },
        );
        this.groups = data.expertsLists
          .filter((g) => this.selfassVisible || !g.selfAssessment)
          .reduce((arr, g) => {
            if (arr.find((el) => g.id === el.id)) {
              return arr;
            }
            return [...arr, g];
          }, [] as V1EntitiesExpertListsPublicAnalyticsIndexExpertList[]);
        this.$emit('select', this.withoutSelfassVisible ? withoutSelfItem : allGroupsItem);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
