var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column heat-map-rows-wrapper",class:{ collapsable : _vm.collapsable },style:(_vm.cssVars)},[_c('div',{staticClass:"heat-map-row tt-light-mono-64--text",class:{
      'heat-map-row_parent' : _vm.hasChildren,
      'heat-map-row_collapsable' : _vm.hasChildren && _vm.collapsable
    },style:(_vm.gridStyle),attrs:{"data-test-label":`rowGrid-${_vm.row.id}`}},_vm._l((_vm.columns),function(col,index){return _c('div',{key:`item-${_vm.row.id}-col-${col.key}`,staticClass:"d-flex flex-row align-center heat-map-row__cell",class:[
        col.cellClass,
        {
          'first-col' : index === 0,
          'last-col' : index === _vm.columns.length - 1,
          'hidden-col' : _vm.isExpanded && _vm.hasOneChild && index > 0
        }],style:({ justifyContent : col.align || 'start' }),attrs:{"data-test-label":`col-${col.key}`}},[(_vm.collapsable && _vm.hasChildren && !index)?_c('TTBtn',{staticClass:"collapse-button mr-1",class:{ 'collapse-button_expanded' : _vm.isExpanded },attrs:{"small":"","plain":"","square":"","color":"tt-light-mono-100","data-test-label":"buttonCollapse"},on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[_c('VIcon',{staticClass:"font-weight-medium",attrs:{"size":"12"}},[_vm._v(" fal fa-chevron-right ")])],1):_vm._e(),_vm._t(`item.${col.key}`,function(){return [(col.isFixed)?[_c('TTTooltip',{attrs:{"top":"","check-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"line-clamp-2 text-pre-line"},'div',attrs,false),on),[_vm._v(" "+_vm._s(col.format ? col.format(_vm.row[col.key]) : _vm.row[col.key])+" ")])]}}],null,true)},[_c('span',{staticClass:"text-pre-line"},[_vm._v(" "+_vm._s(col.format ? col.format(_vm.row[col.key]) : _vm.row[col.key])+" ")])])]:_c('HeatMapColorCellValue',{staticClass:"w-100 color-cell",attrs:{"value":_vm.row[col.key],"min":_vm.minRating,"max":_vm.maxRating,"height":_vm.hasChildren ? _vm.colorCellHeight.parent : _vm.colorCellHeight.child}})]},{"item":_vm.row,"value":_vm.row[col.key],"isParentRow":_vm.hasChildren})],2)}),0),(_vm.hasChildren)?_c('TTExpansionItem',{attrs:{"hide-header":"","data-test-label":"childrenRows"},model:{value:(_vm.isExpanded),callback:function ($$v) {_vm.isExpanded=$$v},expression:"isExpanded"}},_vm._l((_vm.row.children),function(child){return _c('HeatMapRow',_vm._b({key:child.id,staticClass:"heat-map-row_child",attrs:{"row":child,"data-test-label":`childHeatMapRow-${child.id}`},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'HeatMapRow',_vm.$props,false))}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }