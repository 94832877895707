
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'TTDatepickerField',

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default() { return this.$t('datePicker.ddmmyyyy') as string; },
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    rules: {
      type: Array as PropType<Array<() => boolean>>,
      default: () => [],
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowMenu: false,
    };
  },

  computed: {
    formattedDate(): string {
      return (this.value?.length > 0)
        ? this.$dayjs(this.value).format('DD.MM.YYYY') : '';
    },
    datePickerFormattedDate(): string {
      return (this.value?.length > 0)
        ? this.$dayjs(this.value).format('YYYY-MM-DD') : '';
    },
  },

  methods: {
    onValueChange(val: string) {
      this.isShowMenu = false;
      this.$emit('input', val);
    },
  },
});
