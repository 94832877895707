
import Vue from 'vue';
import OpenQuestionsWidget from '@/components/analytics/widgets/OpenQuestionsWidget.vue';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';

export default Vue.extend({
  name: 'TabOpenQuestions',

  components: {
    OpenQuestionsWidget,
    NoAnalyticsTabDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      answersState: {
        isEmpty: false,
        isAnonymous: false,
      },
      selectedGroup: null as number | null,
    };
  },
  computed: {
    isShowTitle() {
      return !this.answersState?.isAnonymous && !this.answersState?.isEmpty;
    },
  },
  methods: {
    discardSelection() {
      (this.$refs.OpenQuestionsWidget as any).selectedGroupId = null;
    },
  },
});
