
import Vue from 'vue';
import DevelopmentTasks from '@/components/analytics/feedback/developmentTasks/DevelopmentTasks.vue';

export default Vue.extend({
  name: 'TabDevelopmentTasks',

  components: { DevelopmentTasks },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },
});
