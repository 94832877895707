
import { defineComponent } from 'vue';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import CompetenciesCommentsDialog from './CompetenciesCommentsDialog.vue';

export default defineComponent({
  name: 'NpsCompetenciesCommentsDialog',

  components: {
    CompetenciesCommentsDialog,
  },

  extends: CompetenciesCommentsDialog,

  methods: {
    async loadComments() {
      try {
        this.loading = true;
        const { data: { groups, comments } } = await tsxassApi.getV1AnalyticsFeedbackNpsQuestionsComments(
          this.surveyId,
          this.questionId || undefined,
          this.userId || undefined,
        );
        this.groups = groups;
        this.comments = comments;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
