
import Vue, { PropType } from 'vue';
import TTExpansionItem from '@/components/ui/TTExpansionItem.vue';
import { HeatMapColumn, HeatMapRow as HeatMapRowType } from './types';
import HeatMapColorCellValue from './HeatMapColorCellValue.vue';

export default Vue.extend({
  name: 'HeatMapRow',

  components: {
    HeatMapColorCellValue,
    TTExpansionItem,
  },

  props: {
    columns: {
      type: Array as PropType<HeatMapColumn[]>,
      default: () => [],
    },
    row: {
      type: Object as PropType<HeatMapRowType>,
      required: true,
    },
    gridStyle: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    // Высота строк (родительских и дочерних)
    rowHeight: {
      type: Object as PropType<{ parent: number, child: number }>,
      default: () => ({ parent: 40, child: 56 }),
    },
    // Высота цветных ячеек (в родительских и дочерних строках)
    colorCellHeight: {
      type: Object as PropType<{ parent: number, child: number }>,
      default: () => ({ parent: 40, child: 30 }),
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isExpandedValue: false,
    };
  },

  computed: {
    hasChildren(): boolean {
      return !!this.row.children?.length || this.row.isParent;
    },
    cssVars(): Record<string, any> {
      return {
        '--parent-row-height': `${this.rowHeight.parent || 40}px`,
        '--child-row-height': `${this.rowHeight.child || 56}px`,
      };
    },
    isExpanded: {
      get(): boolean {
        return !this.collapsable || this.isExpandedValue;
      },
      set(value: boolean) {
        this.isExpandedValue = value;
      },
    },
    hasOneChild(): boolean {
      return this.hasChildren && this.row.children?.length === 1;
    },
  },

  async mounted() {
    await this.$nextTick();
    if (this.hasOneChild) {
      this.isExpanded = true;
    }
  },
});
