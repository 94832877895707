
import Vue, { PropType } from 'vue';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import AnswersApportionmentWidget from './AnswersApportionmentWidget.vue';

export default Vue.extend({
  name: 'ExtraQuestionsAnswersApportionment',

  components: {
    AnswersApportionmentWidget,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    groupId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
  },

  data() {
    return {
      questions: [] as V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion[],
      settings: {
        minValue: 1,
        maxValue: 5,
      },
      loading: false,
    };
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const {
          data: { questions, settings },
        } = await tsxassApi.getV1AnalyticsFeedbackExtraQuestionsScales(
          this.surveyId,
          this.groupId || undefined,
          this.userId || undefined,
        );
        this.questions = questions;
        this.settings = settings;
        this.$emit('questions-empty', !(questions || []).length);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
