import {
  lineChartColors,
  barChartColors,
} from '@/constants/chartColors';

export const getLineChartColor = (lineIndex: number) => lineChartColors[lineIndex % lineChartColors.length];

export const getBarChartColor = (barIndex: number, total: number) => {
  let offset = 0;
  if (total < barChartColors.length) {
    offset = barChartColors.length - total;
  }
  return barChartColors[offset + (barIndex % barChartColors.length)];
};
