
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import fileDownload from '@/helpers/fileDownload';

export default defineComponent({
  name: 'ExportEmployeeAnalyticsToExcel',

  props: {
    surveyId: {
      type: Number,
      default: -1,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasExportToExcelLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_EXCEL);
    },
  },

  methods: {
    async exportToExcel() {
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        const { request } = await tsxassApi.getV1AnalyticsFeedbackXlsxReport(this.surveyId, this.userId, {
          responseType: 'blob',
        });
        if (request.response) {
          fileDownload(request.response, 'Employee.xlsx');
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
