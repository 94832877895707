import {
  AnswerAliases,
  QUESTION_SCALE_ANSWER_ICONS,
  QuestionScaleAnswerIconData,
} from '@/constants/questionScaleAnswers';

const answerAliasesScale: AnswerAliases[] = [
  AnswerAliases.NEVER,
  AnswerAliases.SELDOM,
  AnswerAliases.VERY_RARELY,
  AnswerAliases.RARELY,
  AnswerAliases.SOMETIMES,
  AnswerAliases.HALF,
  AnswerAliases.FREQUENTLY,
  AnswerAliases.OFTEN,
  AnswerAliases.ALMOST_ALWAYS,
  AnswerAliases.ALWAYS,
];

/**
 * Определяет цвет и иконку (в диапазоне грустный - весёлый) для значения оценки на заданной шкале
 * @param value значение оценки
 * @param min левая граница шкалы оценки
 * @param max правая граница шкалы оценки
 * @param inversed инвертированная шкала (от весёлого к грустному)
 * @param iconPrefix префикс в строке иконки ('fal', 'fas')
 * @returns имя класса иконки и цвет
 */
export function getScoreIconByValue(
  value: number = 0,
  min: number = 1,
  max: number = 10,
  inversed: boolean = false,
  iconPrefix: string = 'fal',
): QuestionScaleAnswerIconData {
  if (value < min || value > max) {
    return QUESTION_SCALE_ANSWER_ICONS.cantAnswer;
  }

  const basis = (max - min) / answerAliasesScale.length;
  const index = Math.max(Math.ceil((value - min) / basis) - 1, 0);
  const iconKey = inversed ? answerAliasesScale[answerAliasesScale.length - 1 - index] : answerAliasesScale[index];
  const iconData = QUESTION_SCALE_ANSWER_ICONS[iconKey];

  return {
    ...iconData,
    icon: `${iconPrefix} ${iconData.icon}`,
    iconGrade: `${iconPrefix} ${iconData.iconGrade}`,
  };
}

/**
 * Определяет цвет и иконку (в диапазоне грустный - весёлый) по алиасу ответа
 * @param iconName алиас ответа
 * @param iconPrefix префикс в строке иконки ('fal', 'fas')
 * @returns имя класса иконки и цвет
 */
export function getScoreIconByName(
  iconName: AnswerAliases,
  iconPrefix: string = 'fal',
): QuestionScaleAnswerIconData | null {
  const iconData = QUESTION_SCALE_ANSWER_ICONS[iconName];
  if (!iconData) {
    return null;
  }

  return {
    ...iconData,
    icon: `${iconPrefix} ${iconData.icon}`,
    iconGrade: `${iconPrefix} ${iconData.iconGrade}`,
  };
}

/**
 * Определяет цвет и иконку (в диапазоне грустный - весёлый) для значения оценки на заданной большой шкале
 * @param value значение оценки
 * @param min левая граница шкалы оценки
 * @param max правая граница шкалы оценки
 * @param inversed инвертированная шкала (от весёлого к грустному)
 * @param iconPrefix префикс в строке иконки ('fal', 'fas')
 * @returns имя класса иконки и цвет
 */
export function getLargeScaleScoreIconByValue(
  value: number = 0,
  min: number = 1,
  max: number = 10,
  inversed: boolean = false,
  iconPrefix: string = 'fal',
): QuestionScaleAnswerIconData {
  if (value < min || value > max) {
    return QUESTION_SCALE_ANSWER_ICONS.cantAnswer;
  }
  let index = 0;
  if (value <= 7.9) {
    index = 0;
  } else if (value <= 8.9) {
    index = 5;
  } else if (value <= 9.5) {
    index = 7;
  } else {
    index = 9;
  }
  const iconKey = inversed ? answerAliasesScale[answerAliasesScale.length - 1 - index] : answerAliasesScale[index];
  const iconData = QUESTION_SCALE_ANSWER_ICONS[iconKey];

  return {
    ...iconData,
    icon: `${iconPrefix} ${iconData.icon}`,
    iconGrade: `${iconPrefix} ${iconData.iconGrade}`,
  };
}

/**
 * Определяет цвет и иконку (только один из трёх вариантов грустный - весёлый)
 * для значения оценки в диапазоне [-100,100]
 * @param value значение оценки
 * @returns имя класса иконки и цвет
 */
export function getNpsScaleScoreIconByValue(
  value: number = 0,
): QuestionScaleAnswerIconData {
  if (value < 10) {
    return {
      icon: 'fa-face-frown',
      iconGrade: 'fa-face-frown',
      color: '#E5453F',
      mutedColorCode: '#E5453F',
    };
  }
  if (value < 50) {
    return {
      icon: 'fa-face-smile',
      iconGrade: 'fa-face-smile',
      color: '#FFBF00',
      mutedColorCode: '#FFBF00',
    };
  }
  return {
    icon: 'fa-face-laugh',
    iconGrade: 'fa-face-laugh',
    color: '#327E31',
    mutedColorCode: '#327E31',
  };
}
