/**
 * Максимальное значение для первого квадранта графика Джохари
 * ---
 * Если кол-во вариантов оценок в шкале четное, то мы делим это кол-во пополам
 * и в таком случае в каждый квадрат попадает одинаковое кол-во оценок
 * Если кол-во вариантов оценок в шкале нечетное, то так же делим кол-во оценок пополам,
 * после чего округляем получившееся число в большую сторону.
 * Например, если шкала от 1 до 5, то таким числом будет 3.
 * Таким образом оценки, которая меньше либо равны данному числу, будут входить в первую половину Джохари
 *
 * Если же шкала длинная (0..10 или больше), то на каждые 5 единиц шкалы увеличиваем среднее значение ещё на 2 балла.
 * Например, для шкалы 0..10 среднее число будет 7 (5 + 2 = 7)
 */

const getJohariMiddleValue = (min: number, max: number): number => {
  if (max > 9) {
    return 7;
  }
  const leftHalfNumbersCount = Math.ceil((max - min + 1) / 2);
  return min + leftHalfNumbersCount - 1;
};

export default getJohariMiddleValue;
