var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"heat-map p-relative"},[_c('div',{key:_vm.leftVisibleScrollableColIndex,staticClass:"header p-relative",class:{ 'header_scrollable' : _vm.hasScroll },style:([_vm.gridStyle, _vm.rowStyle]),attrs:{"data-test-label":"header"}},[_vm._l((_vm.visibleColumns),function(col,index){return _c('TTTooltip',{key:col.key,attrs:{"bottom":"","disabled":!col.hint},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"header__item tt-light-mono-46--text",class:[
            {
              'first-col' : index === 0,
              'last-col' : index === _vm.visibleColumns.length - 1,
              'vertical-label' : _vm.verticalLabelsInHeader
            },
            `text-${_vm.verticalLabelsInHeader ? 'start' : col.align || 'start'}`,
            col.headerCellClass
          ],attrs:{"data-test-label":`col-${col.key}`}},'div',attrs,false),on),[_vm._t(`header.${col.key}`,function(){return [_c('p',{staticClass:"mb-0",domProps:{"textContent":_vm._s(col.title)}})]},{"column":col,"title":col.title})],2)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(col.hint)+" ")])])}),(_vm.hasScroll)?_c('ChartScrollButtons',{staticClass:"scroll-buttons",attrs:{"left-button-disabled":_vm.leftVisibleScrollableColIndex === 0,"right-button-disabled":_vm.isScrolledToEnd,"data-test-label":"buttonsScroll"},on:{"scroll-left":function($event){_vm.leftVisibleScrollableColIndex -= 1},"scroll-right":function($event){_vm.leftVisibleScrollableColIndex += 1}}}):_vm._e()],2),_vm._l((_vm.rows),function(row){return _c('HeatMapRow',{key:`row-${row.id}`,staticClass:"heat-map__row",style:(_vm.rowStyle),attrs:{"columns":_vm.visibleColumns,"row":row,"min-rating":_vm.minRating,"max-rating":_vm.maxRating,"grid-style":_vm.gridStyle,"row-height":_vm.rowHeight,"color-cell-height":_vm.colorCellHeight,"collapsable":_vm.collapsable,"data-test-label":`row-${row.id}`},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})}),_c('TTLoader',{attrs:{"value":_vm.loading}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }