import transport from '@/services/api/report-service/transport';
import * as methods from '@/services/api/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const reportCreate = (data, options) => transport({
  url: 'v1/tsxass/report',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const archiveCreate = (data, options) => transport({
  url: 'v1/tsxass/archive',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const archiveStatus = (options) => transport({
  url: 'v1/tsxass/archive/status',
  method: methods.HTTP_GET,
  ...options,
});
