
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import ProgressWithPercentage from '@/components/analytics/charts/ProgressWithPercentage.vue';

export default {
  name: 'DevelopmentPlanProgress',

  components: {
    ProgressWithPercentage,
  },

  inject: ['RouteNames'],

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
      to: {
        name: this.RouteNames.R_APP_SURVEY_ANALYTICS,
        params: { surveyId: this.surveyId },
        query: {
          userId: this.userId,
          tab: 'development_tasks',
        },
      },
      value: undefined as number | undefined,
    };
  },

  mounted() {
    this.loadProgress();
  },

  methods: {
    async loadProgress() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.postV1AnalyticsManagementResult({
          surveyId: this.surveyId,
          userIds: this.userId ? [this.userId] : undefined,
        });
        this.value = data.result[0]?.developmentTasksProgress;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
