
import Vue from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import TTDatepickerField from '@/components/ui/TTDatepickerField.vue';
import { tsxassApi } from '@/services/api';
import NavigateToYandexGPT from '@/views/app/Analytics/NavigateToYandexGPT.vue';
import { V1EntitiesDevelopmentTasksQuestionsQuestion } from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default Vue.extend({
  name: 'DevelopmentTaskEditDialog',

  components: {
    TTActionDialog,
    TTLoader,
    TTDatepickerField,
    NavigateToYandexGPT,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: [String, Number],
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      formData: { ...this.getInitialFormValue() },
      questions: [] as V1EntitiesDevelopmentTasksQuestionsQuestion[],
      requiredFields: ['name', 'scheduledAt'],
    };
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    validationRules() {
      return {
        notEmpty: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
        hasValue: [(v: any): boolean | string => v ?? (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isAllRequiredFieldsFilled(): boolean {
      return Object.keys(this.getInitialFormValue())
        .every((v) => (this.requiredFields.includes(v) ? this.formData[v] : true));
    },
    isCreating(): boolean {
      return !(this.taskId);
    },
    headerText() {
      return this.isCreating
        ? this.$t('analytics.developmentTasks.addTask')
        : this.$t('analytics.developmentTasks.editTask');
    },
    submitText() {
      return this.isCreating
        ? this.$t('addButton')
        : this.$t('save');
    },
    minDateTime(): string {
      return this.$dayjs().add(1, 'd').format('YYYY-MM-DD');
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      } else {
        await this.getIndicators();
        if (!this.isCreating) {
          await this.getTask();
        }
      }
    },
  },

  methods: {
    getInitialFormValue() {
      return {
        name: '',
        questionId: undefined,
        scheduledAt: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),
      };
    },
    async submitForm() {
      try {
        this.loading = true;
        if (this.isCreating) {
          await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasks({
            ...this.formData,
            surveyId: this.surveyId,
            userId: this.userId,
          });
          this.$emit('submit');
        } else {
          await tsxassApi.putV1AnalyticsFeedbackDevelopmentTasksId(
            this.taskId,
            this.surveyId,
            this.formData,
            this.userId,
          );
          this.$emit('submit');
        }
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form.resetValidation();
      this.formData = { ...this.getInitialFormValue() };
      this.$emit('cancel');
    },
    async getIndicators() {
      try {
        this.loading = true;
        const { data: { questions } } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksQuestions(
          this.surveyId,
          this.userId,
        );
        this.questions = questions;
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async getTask() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksId(
          this.taskId,
          this.surveyId,
          this.userId,
        );
        this.formData = {
          ...this.getInitialFormValue(),
          ...data,
        };
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
