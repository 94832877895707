
import Vue, { defineComponent } from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import TTNumberInput from '@/components/ui/TTNumberInput.vue';
import type { V1EntitiesDevelopmentTasksWeightsWeight } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

type TaskWeight = V1EntitiesDevelopmentTasksWeightsWeight & {
  name: string;
};

export default defineComponent({
  name: 'DevelopmentTaskModifyWeightsDialog',

  components: {
    TTActionDialog,
    TTLoader,
    TTNumberInput,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: undefined,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      weights: [] as TaskWeight[],
    };
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    weightsDistributionIncorrect(): boolean {
      return this.weights.length > 0 && this.weights.reduce((acc, task) => task.weight + acc, 0) !== 100;
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      } else {
        await this.getWeights();
      }
    },
  },

  methods: {
    async submitForm() {
      try {
        this.loading = true;
        await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(
          this.surveyId,
          {
            developmentTasks: this.weights,
          },
          this.userId,
        );
        this.$emit('submit');
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form.resetValidation();
      this.weights = [];
      this.$emit('cancel');
    },
    async getWeights() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasks(this.surveyId, this.userId);
        this.weights = data.developmentTasks.map((task) => ({
          name: task.name,
          id: task.id,
          weight: task.weight,
        }));
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async setWeightsAutomatically() {
      try {
        this.loading = true;
        await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(this.surveyId, this.userId);
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
        this.$emit('submit');
      }
    },
  },
});
