
import Vue from 'vue';
import { tsxassApi } from '@/services/api';
import PageTitle from '@/components/common/PageTitle.vue';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesUsersPublicShowUser } from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';

export default Vue.extend({
  name: 'AnalyticsPageTitle',

  components: {
    UserAvatar,
    PageTitle,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      user: null as V1EntitiesUsersPublicShowUser | null,
    };
  },
  mounted() {
    if (this.userId) {
      this.loadUserInfo();
    }
  },
  methods: {
    async loadUserInfo() {
      try {
        const { data } = await tsxassApi.getV1UsersId(this.userId);
        this.user = data?.user || null;
      } catch (err) {
        errorHandler(err);
      }
    },
  },
});
