
import Vue from 'vue';

export default Vue.extend({
  name: 'JohariHelpDialog',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShowDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
  },
});
