
import Vue from 'vue';
import ExtraQuestionsHeatMap from '@/components/analytics/widgets/ExtraQuestionsHeatMap.vue';
import ExtraQuestionsAnswersApportionment from '@/components/analytics/widgets/ExtraQuestionsAnswersApportionment.vue';
import BehaviorDynamicsWrapper from '@/components/analytics/widgets/BehaviorDynamicsWrapper.vue';
import SelectExpertsGroup from '@/components/analytics/widgets/SelectExpertsGroup.vue';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';

export default Vue.extend({
  name: 'TabAdditionalQuestions',

  components: {
    ExtraQuestionsHeatMap,
    ExtraQuestionsAnswersApportionment,
    BehaviorDynamicsWrapper,
    SelectExpertsGroup,
    NoAnalyticsTabDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      groupId: null as number | null,
      isQuestionsEmpty: false,
      isBehaviorDynamicsEmpty: false,
    };
  },

  computed: {
    isTabEmpty(): boolean {
      return this.isQuestionsEmpty && this.isBehaviorDynamicsEmpty;
    },
  },
});
