import { Chart, Plugin } from 'chart.js';

interface JohariGridlinesOptions {
  // Краевые значения графика
  bounds: {
    minX: number,
    maxX: number,
    minY: number,
    maxY: number,
  },
}

/**
 * Рисует линии сетки для графика Окно Джохари
 */
export default {
  id: 'johariGridlines',
  beforeDraw(chart: Chart, _args: any, options: JohariGridlinesOptions) {
    const {
      ctx,
      scales: { x, y },
    } = chart;

    const {
      bounds: {
        minX,
        maxX,
        minY,
        maxY,
      },
    } = options;

    const midX = x.getPixelForValue((maxX - minX) / 2 + minX);
    const midY = y.getPixelForValue((maxY - minY) / 2 + minY);

    ctx.strokeStyle = '#EBECED';

    // Вертикальная прямая по центру
    ctx.beginPath();
    ctx.moveTo(midX, y.bottom);
    ctx.lineTo(midX, y.top);
    ctx.stroke();
    // Вертикальная прямая по правой границе
    ctx.beginPath();
    ctx.moveTo(x.right, y.bottom);
    ctx.lineTo(x.right, y.top);
    ctx.stroke();

    ctx.setLineDash([2, 2]);
    // Горизонтальная прямая по верхней границе
    ctx.beginPath();
    ctx.moveTo(x.left, y.top);
    ctx.lineTo(x.right, y.top);
    ctx.stroke();
    // Горизонтальная прямая по центру
    ctx.beginPath();
    ctx.moveTo(x.left, midY);
    ctx.lineTo(x.right, midY);
    ctx.stroke();
    ctx.setLineDash([]);
  },
} as Plugin<'scatter', any>;
