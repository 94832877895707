import { Chart, Plugin } from 'chart.js';

const OFFSET = 6;
const WIDTH = 6;

/**
 * Добавляет на концах осей стрелки направления
 */
export default {
  id: 'axisArrows',
  beforeDraw(chart: Chart) {
    const { ctx } = chart;
    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    const height = WIDTH * (Math.sqrt(3) / 2);

    // @ts-ignore
    const color = chart.config.options?.scales?.y?.grid?.borderColor as string || '#e5e5e5';
    ctx.fillStyle = color;
    ctx.strokeStyle = color;

    // y axis
    ctx.beginPath();
    ctx.moveTo(xScale.left - WIDTH / 2, yScale.top - OFFSET);
    ctx.lineTo(xScale.left, yScale.top - OFFSET - height);
    ctx.lineTo(xScale.left + WIDTH / 2, yScale.top - OFFSET);
    ctx.lineTo(xScale.left - WIDTH / 2, yScale.top - OFFSET);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(xScale.left, yScale.top);
    ctx.lineTo(xScale.left, yScale.top - OFFSET);
    ctx.stroke();

    // x axis
    ctx.beginPath();
    ctx.moveTo(xScale.right + OFFSET, yScale.bottom - WIDTH / 2);
    ctx.lineTo(xScale.right + OFFSET + height, yScale.bottom);
    ctx.lineTo(xScale.right + OFFSET, yScale.bottom + WIDTH / 2);
    ctx.lineTo(xScale.right + OFFSET, yScale.bottom - WIDTH / 2);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(xScale.right, yScale.bottom);
    ctx.lineTo(xScale.right + OFFSET, yScale.bottom);
    ctx.stroke();
  },
} as Plugin<'scatter', any>;
