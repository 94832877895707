
import Vue, { PropType } from 'vue';
import errorHandler from '@/helpers/errorHandler';
import BarChart from '@/components/analytics/charts/BarChart.vue';
import AnswersTable from '@/components/analytics/charts/AnswersTable.vue';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import { AnswersTableItem, BarChartDataSet } from '@/components/analytics/charts/types';
import TTLoader from '@/components/ui/TTLoader.vue';
import { getBarChartColor } from '@/helpers/chartColors';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend,
  V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue,
  V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis,
  V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer,
} from '@/services/api/tsxass';

const floatStringToPercent = (value: string) => Number((parseFloat(value) * 100).toFixed());

export default Vue.extend({
  name: 'BehaviorDynamics',

  components: {
    BarChart,
    TTLoader,
    AnswersTable,
    NoFilteredDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    groupId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    chartScroll: {
      type: Boolean,
      default: true,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
    behaviorDynamicsData: {
      type: Object,
      default: () => ({
        chartData: {
          axis: [],
          legend: [],
          values: [],
        },
        answers: [],
      }),
    },
    disableFetch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: {
        axis: [] as V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis[],
        legend: [] as V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend[],
        values: [] as V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue[],
      },
      chartDataLoading: false,
      answers: [] as V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer[],
      answersLoading: false,
    };
  },

  computed: {
    widgetIsLoading(): boolean {
      return this.answersLoading || this.chartDataLoading;
    },
    chartDataIsEmpty(): boolean {
      return (!this.chartData.values.length && !this.chartDataLoading);
    },
    answersDataIsEmpty(): boolean {
      return !this.answers.length && !this.answersLoading;
    },
    title(): string {
      return this.chartData?.axis?.[0]?.questionName
        || this.answers?.[0]?.questionName
        || '';
    },
    xLabels(): string[] {
      return this.chartData ? this.chartData.axis.map((tick) => tick.name) : [];
    },
    dataSets(): BarChartDataSet[] {
      const xValuesMap = Object.fromEntries(this.chartData.axis.map((item, index) => [item.id, index]));

      return this.chartData.legend.map((legendItem, legendIndex) => {
        const data = this.chartData.values
          .filter((value) => String(value.legendId) === String(legendItem.id))
          .map((tickValue) => ({
            x: xValuesMap[tickValue.axisId],
            y: floatStringToPercent(tickValue.value),
            label: `${floatStringToPercent(tickValue.value)}%`,
          }));

        const color = getBarChartColor(legendIndex, this.chartData.legend.length);
        const dateString = this.$dayjs(legendItem.id).format('D MMMM YY');

        return {
          label: this.$t('analytics.additionalQuestions.resultsDate', { date: dateString }) as string,
          type: 'bar',
          data,
          borderColor: color,
          backgroundColor: color,
        };
      });
    },
    answersTableItems(): AnswersTableItem[] {
      if (!this.answers) return [];
      return this.answers.map(
        (answer, index): AnswersTableItem => ({
          id: String(index),
          answer: answer.name,
          label: `${floatStringToPercent(answer.percent)}%`,
          percentage: floatStringToPercent(answer.percent),
          peopleCount: answer.count,
        }),
      );
    },
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.loadData();
      },
    },
    async widgetIsLoading(newValue, oldValue) {
      if (!newValue && oldValue) {
        await this.$nextTick();
        this.$emit('ready', this.chartDataIsEmpty && this.answersDataIsEmpty);
      }
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    yScaleLabelsFormatter(val: number): string {
      return `${val}%`;
    },
    loadData() {
      if (this.disableFetch) {
        const {
          chartData,
          answers,
        } = this.behaviorDynamicsData;
        this.chartData = chartData;
        this.answers = answers;
        return;
      }
      this.loadChartData();
      this.loadAnswers();
    },
    async loadChartData() {
      try {
        this.chartDataLoading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackBehaviorDynamics(
          this.surveyId,
          this.userId || undefined,
          this.groupId || undefined,
        );
        this.chartData = {
          axis: data.axis || [],
          legend: data.legend || [],
          values: data.values || [],
        };
        this.$emit('behavior-dynamics-empty', !(data.values || []).length);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.chartDataLoading = false;
      }
    },
    async loadAnswers() {
      try {
        this.answersLoading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackBehaviorAnswers(
          this.surveyId,
          this.userId || undefined,
          this.groupId || undefined,
        );
        this.answers = data.answers || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.answersLoading = false;
      }
    },
  },
});
