
import Vue from 'vue';
import { ChartDataset } from 'chart.js';
import errorHandler from '@/helpers/errorHandler';
import BarChart from '@/components/analytics/charts/BarChart.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import {
  V1EntitiesAnalyticsFeedbackDestructorsSurveySettings,
  V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis,
  V1EntitiesAnalyticsFeedbackDestructorsSurveyValue,
} from '@/services/api/tsxass';
import { defaultBarChartColor } from '@/constants/chartColors';
import { tsxassApi } from '@/services/api';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import SelectExpertsGroup from '@/components/analytics/widgets/SelectExpertsGroup.vue';

export default Vue.extend({
  name: 'SurveyDestructors',

  components: {
    BarChart,
    SectionTitle,
    TTLoader,
    SelectExpertsGroup,
    NoFilteredDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: {
        settings: {} as V1EntitiesAnalyticsFeedbackDestructorsSurveySettings,
        axis: [] as V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis[],
        values: [] as V1EntitiesAnalyticsFeedbackDestructorsSurveyValue[],
        state: {
          belowAnonymityThreshold: false,
        },
      },
      loading: false,
      groupId: null as number | null,
    };
  },

  computed: {
    chartDataIsNotEmpty(): boolean {
      return !!this.chartData?.values?.length;
    },
    xLabels(): string[] {
      return this.chartData ? this.chartData.axis.map((tick) => tick.name) : [];
    },
    dataSets(): ChartDataset[] {
      const xValuesMap = Object.fromEntries(this.chartData.axis.map((item, index) => [item.id, { ...item, index }]));
      const data = (this.chartData?.values || []).map((tickValue) => ({
        hint: xValuesMap[tickValue.axisId].description,
        x: xValuesMap[tickValue.axisId].index,
        y: tickValue.value,
        label: tickValue.hint,
      }));

      return [
        {
          label: '',
          type: 'bar',
          data,
          borderColor: defaultBarChartColor,
          backgroundColor: defaultBarChartColor,
        },
      ];
    },
    maxY(): number {
      return this.chartData ? this.chartData.settings.maxValue : 5;
    },
    belowAnonymityThreshold() {
      return !this.chartData.state?.display && this.chartData.state?.anonymityThresholdReached;
    },
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackDestructorsSurvey(
          this.surveyId,
          this.groupId || undefined,
          this.userId || undefined,
        );
        this.chartData = {
          settings: data.settings || {},
          state: data.state || {},
          axis: data.axis || [],
          values: data.values || [],
        };
        this.$emit('destructors-survey-state', {
          isEmpty: !(data.values || []).length,
          isAnonymous: data?.state?.anonymityThresholdReached || false,
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
