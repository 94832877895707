
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import DevelopmentTasksTable from '@/components/analytics/feedback/developmentTasks/DevelopmentTasksTable.vue';
import DevelopmentTaskEditDialog from '@/components/analytics/feedback/developmentTasks/DevelopmentTaskEditDialog.vue';
import DevelopmentTaskModifyWeightsDialog
  from '@/components/analytics/feedback/developmentTasks/DevelopmentTaskModifyWeightsDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import DevelopmentTaskModifyProgressDialog
  from '@/components/analytics/feedback/developmentTasks/DevelopmentTaskModifyProgressDialog.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'DevelopmentTasks',
  components: {
    DevelopmentTaskModifyProgressDialog,
    TTLoader,
    DevelopmentTaskModifyWeightsDialog,
    DevelopmentTaskEditDialog,
    DevelopmentTasksTable,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tasks: [] as V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem[],
      loading: false,
      showAddingDialog: false,
      showWeightsDialog: false,
      showProgressDialog: false,
      selectedTaskId: null as string | number | null,
    };
  },

  computed: {
    ...mapState('profile', { profile: 'profile' }),
    ...mapState('engagementInfo', { currentUser: 'user' }),
    requestParams(): any[] {
      return [
        this.surveyId,
        this.computedUserId,
      ];
    },
    // Проверяем не только сумму процентов, но и наличие нулевых весов
    isWrongWeights(): boolean {
      return this.tasks.length > 0
        && (this.tasks.reduce((acc, task) => acc + task.weight, 0) !== 100 || this.tasks.some((task) => !task.weight));
    },
    isDirector(): boolean {
      return this.profile?.roles?.find((role) => ['admin', 'manager'].includes(role.role));
    },
    computedUserId(): string {
      return this.userId || this.currentUser.userId;
    },
  },

  watch: {
    requestParams: {
      immediate: true,
      handler() {
        this.loadTasks();
      },
    },
  },

  methods: {
    async loadTasks() {
      try {
        this.loading = true;
        const {
          data: { developmentTasks },
        } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasks(this.surveyId, this.computedUserId || undefined);
        this.tasks = developmentTasks || [];
      } catch (err) {
        errorHandler(err);
        this.$emit('error', err);
      } finally {
        this.loading = false;
      }
    },
    async setWeightsAutomatically() {
      try {
        this.loading = true;
        await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(this.surveyId, this.computedUserId);
        await this.loadTasks();
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async finishTask(id: number) {
      try {
        this.loading = true;
        await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id, this.surveyId, this.computedUserId);
        await this.loadTasks();
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteTask(id: number) {
      try {
        this.loading = true;
        await tsxassApi.deleteV1AnalyticsFeedbackDevelopmentTasksId(id, this.surveyId, this.computedUserId);
        await this.loadTasks();
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    addTask() {
      this.selectedTaskId = null;
      this.showAddingDialog = true;
    },
    editTask(id: number) {
      this.selectedTaskId = id;
      this.showAddingDialog = true;
    },
    setTaskProgress(id: number) {
      this.selectedTaskId = id;
      this.showProgressDialog = true;
    },
    onTaskEditSubmit() {
      this.showAddingDialog = false;
      this.loadTasks();
    },
    onWeightsEditSubmit() {
      this.showWeightsDialog = false;
      this.loadTasks();
    },
    onTaskModifyProgressSubmit() {
      this.showProgressDialog = false;
      this.loadTasks();
    },
  },
});
