export const lineChartColors = [
  '#601EF3',
  '#FFC700',
  '#FF26E1',
  '#00B8FF',
  '#00D358',
  '#FF9974',
  '#0052B4',
  '#0B1218',
  '#E8505B',
];

export const barChartColors = [
  '#8F9295',
  '#C4C6C8',
  '#64B5F6',
  '#1565C0',
];

export const defaultBarChartColor = '#00A0F2';
