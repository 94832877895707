
import Vue, { defineComponent } from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import TTNumberInput from '@/components/ui/TTNumberInput.vue';
import { V1EntitiesDevelopmentTasksShow } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import type { V1EntitiesDevelopmentTasksQuestionsQuestion } from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

type InitialFormData = {
  name: string;
  questionId: number;
  scheduledAt: string;
};

const INITIAL_FORM_DATA: InitialFormData & Partial<V1EntitiesDevelopmentTasksShow> = {
  name: '',
  questionId: 0,
  scheduledAt: '',
};

export default defineComponent({
  name: 'DevelopmentTaskModifyProgressDialog',

  components: {
    TTActionDialog,
    TTLoader,
    TTNumberInput,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: [String, Number],
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      formData: { ...INITIAL_FORM_DATA },
      questions: [] as V1EntitiesDevelopmentTasksQuestionsQuestion[],
    };
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    formattedDateTime(): string {
      return this.$dayjs(this.formData.scheduledAt).format('DD-MM-YYYY');
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      } else {
        await this.getIndicators();
        if (!this.isCreating) {
          await this.getTask();
        }
      }
    },
  },

  methods: {
    async submitForm() {
      try {
        this.loading = true;
        await tsxassApi.putV1AnalyticsFeedbackDevelopmentTasksId(
          Number(this.taskId),
          this.surveyId,
          this.formData,
          this.userId,
        );
        this.$emit('submit');
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form.resetValidation();
      this.formData = { ...INITIAL_FORM_DATA };
      this.$emit('cancel');
    },
    async getIndicators() {
      try {
        this.loading = true;
        const {
          data: { questions },
        } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksQuestions(this.surveyId, this.userId);
        this.questions = questions;
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async getTask() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksId(
          Number(this.taskId),
          this.surveyId,
          this.userId,
        );
        this.formData = {
          ...INITIAL_FORM_DATA,
          ...data,
        };
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
