
import Vue, { PropType } from 'vue';
import {
  ChartConfiguration, ChartDataset, Chart, registerables, LegendItem,
} from 'chart.js';
import XAxisRedrawPlugin from '@/utils/charts/plugins/XAxisRedrawPlugin';
import CustomChartLegendPlugin from '@/utils/charts/plugins/CustomChartLegendPlugin';
import LineChartCustomTooltipPlugin from '@/utils/charts/plugins/LineChartCustomTooltipPlugin';
import labelToArray from '@/utils/charts/labelToArray';
import type { CustomTooltipData } from '@/utils/charts/plugins/LineChartCustomTooltipPlugin';
import ChartLegend from './ChartLegend.vue';
import ChartScrollButtons from './ChartScrollButtons.vue';
import { LineChartDataSet, LineChartDataSetData } from './types';
import LineChartCustomTooltip from './LineChartCustomTooltip.vue';

Chart.register(...registerables);

const MIN_X_STEP_SIZE = 270;
const TICK_LENGTH = 32;
const TICK_FONT_SIZE = 12;

export default Vue.extend({
  name: 'LineChart',

  components: {
    ChartLegend,
    ChartScrollButtons,
    LineChartCustomTooltip,
  },

  props: {
    xLabels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    dataSets: {
      type: Array as PropType<LineChartDataSet[]>,
      default: () => [],
    },
    minY: {
      type: Number,
      default: 0,
    },
    maxY: {
      type: Number,
      default: 100,
    },
    yStepSize: {
      type: Number,
      default: 1,
    },
    hideLegend: {
      type: Boolean,
      default: false,
    },
    showPoints: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: null as Chart<'line', LineChartDataSetData[]> | null,
      xScaleWidth: 0,
      leftValueIndex: 0,
      legendItems: [] as LegendItem[],
      tooltipData: { title: '', items: [] } as CustomTooltipData,
    };
  },

  computed: {
    visibleTicksCount(): number {
      return Math.max(Math.floor(this.xScaleWidth / MIN_X_STEP_SIZE), 2);
    },
    currentTickWidth(): number {
      return this.xScaleWidth / this.visibleTicksCount;
    },
    rightValueIndex(): number {
      if (this.chartLabels.length <= this.visibleTicksCount) {
        return this.chartLabels.length;
      }

      return this.leftValueIndex + this.visibleTicksCount + 1;
    },
    chartLabels(): Array<string | number> {
      // Добавляем дополнительный пустой лэйбл в конец (создает отступ справа от самого графика)
      return [...this.xLabels, ''];
    },
    preparedDataSets(): ChartDataset<'line', LineChartDataSetData[]>[] {
      return this.dataSets.map((dataSet) => ({
        ...dataSet,
        parsing: {
          yAxisKey: 'y',
          xAxisKey: 'x',
        },
      }));
    },
    chartSettings(): ChartConfiguration<'line', LineChartDataSetData[]> {
      const self: Vue = this;

      return {
        type: 'line',
        data: {
          labels: this.chartLabels,
          datasets: this.preparedDataSets,
        },
        options: {
          interaction: {
            mode: 'nearest',
            axis: 'xy',
            intersect: true,
          },
          responsive: true,
          onResize: this.onChartResize,
          maintainAspectRatio: false,
          // @ts-ignore
          clip: false,
          scales: {
            y: {
              position: 'right',
              suggestedMin: this.minY,
              suggestedMax: this.maxY,
              ticks: {
                stepSize: this.yStepSize,
                color: '#8F9295',
                align: 'end',
                padding: 0,
              },
              grid: {
                borderDash: [2, 2],
                tickBorderDash: [2, 2],
                tickLength: TICK_LENGTH,
                borderWidth: 1,
                borderColor: 'D8D9DA',
                color: 'D8D9DA',
              },
            },
            x: {
              // @ts-ignore
              min: self.leftValueIndex,
              // @ts-ignore
              max: Math.min(self.leftValueIndex + self.visibleTicksCount, self.chartLabels.length),
              grid: {
                borderColor: '#EBECED',
                color: '#EBECED',
                borderWidth: 1,
                tickLength: 2,
              },
              ticks: {
                align: 'start',
                callback(val, index) {
                  // Скрываем лэйбл у последнего отображаемого тика
                  // @ts-ignore
                  const label = index >= self.visibleTicksCount ? '' : self.xLabels[val as number] || '';
                  // @ts-ignore
                  return labelToArray(label, Math.floor(self.currentTickWidth / TICK_FONT_SIZE));
                },
                font: {
                  size: TICK_FONT_SIZE,
                },
                padding: 6,
              },
            },
          },
          elements: {
            point: {
              // @ts-ignore
              radius: self.showPoints ? 6 : 0,
              pointStyle: 'circle',
              hoverRadius: 8,
              borderColor: 'white',
              borderWidth: 2,
              hoverBorderWidth: 2,
            },
            line: {
              borderWidth: 3,
              spanGaps: true,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            lineChartCustomTooltip: {
              tooltip: {
                tooltipEl: () => self.$refs.chartTooltip as HTMLDivElement,
              },
              callbacks: {
                onTooltipDataUpdate: (e) => {
                  // @ts-ignore
                  self.tooltipData = e;
                },
              },
            },
          },
        },
        plugins: [
          XAxisRedrawPlugin(TICK_LENGTH),
          CustomChartLegendPlugin(this, 'legendItems'),
          LineChartCustomTooltipPlugin,
        ],
      };
    },
  },

  watch: {
    chartSettings: {
      handler(val) {
        if (!this.chart) {
          return;
        }

        const { data, options } = val;
        const { datasets, labels } = data;
        this.chart.data.datasets = datasets;
        this.chart.data.labels = labels;
        this.chart.options = options;

        this.chart.update();
        this.onChartResize();
      },
    },
  },

  mounted() {
    this.chart = new Chart(this.$refs.canvasEl as HTMLCanvasElement, this.chartSettings);
    this.onChartResize();
  },

  beforeDestroy() {
    this.chart?.destroy();
  },

  methods: {
    async onChartResize() {
      if (!this.chart) {
        return;
      }

      this.xScaleWidth = this.chart?.scales?.x?.maxWidth || 0;
    },
    moveLeft() {
      this.leftValueIndex -= 1;
    },
    moveRight() {
      this.leftValueIndex += 1;
    },
  },
});
