
import Vue, { PropType } from 'vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import HeatMapRow from './HeatMapRow.vue';
import ChartScrollButtons from '../ChartScrollButtons.vue';
import { HeatMapColumn, HeatMapRow as HeatMapRowType } from './types';

export default Vue.extend({
  name: 'HeatMap',

  components: {
    HeatMapRow,
    ChartScrollButtons,
    TTLoader,
  },

  props: {
    columns: {
      type: Array as PropType<HeatMapColumn[]>,
      default: () => [],
    },
    rows: {
      type: Array as PropType<HeatMapRowType[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    // Отображать подписи солбцов вертикально
    verticalLabelsInHeader: {
      type: Boolean,
      default: false,
    },
    // Отступы внутри строк
    rowPadding: {
      type: Object as PropType<{ left: number, right: number }>,
      default: () => ({ left: 20, right: 30 }),
    },
    // Высота строк (родительских и дочерних)
    rowHeight: {
      type: Object as PropType<{ parent: number, child: number }>,
      default: () => ({ parent: 40, child: 56 }),
    },
    // Высота цветных ячеек (в родительских и дочерних строках)
    colorCellHeight: {
      type: Object as PropType<{ parent: number, child: number }>,
      default: () => ({ parent: 40, child: 30 }),
    },
    // Минимальная ширина столбца с цветной ячейкой
    scrollableColMinWidth: {
      type: Number,
      default: 65,
    },
    // Расстояние между столбцами
    colsGap: {
      type: Number,
      default: 12,
    },
    hideScroll: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      resizeObserver: null as ResizeObserver | null,
      parentWidth: 0,
      leftVisibleScrollableColIndex: 0,
    };
  },

  computed: {
    rowStyle(): Record<string, string> {
      return {
        paddingLeft: `${this.rowPadding.left}px`,
        paddingRight: `${this.rowPadding.right}px`,
      };
    },
    gridStyle(): Record<string, string> {
      const gridTemplateColumns = this.visibleColumns
        .map((col) => (col.width ? `${col.width}px` : `minmax(${this.scrollableColMinWidth - this.colsGap}px, 1fr)`))
        .join(' ');

      return {
        gridTemplateColumns,
        gap: `${this.colsGap}px`,
      };
    },
    fixedColumns(): HeatMapColumn[] {
      return this.columns.filter((col) => col.isFixed);
    },
    // Суммарная ширина фиксированных столбцов
    fixedColumnsWidth(): number {
      return this.fixedColumns.reduce((acc, col) => acc + (col.width || 0), 0)
        + (this.fixedColumns.length - 1) * this.colsGap;
    },
    scrollableColumns(): HeatMapColumn[] {
      return this.columns.filter((col) => !col.isFixed);
    },
    // Число видимых нефиксированных столбцов
    visibleScrollableColumnsCount(): number {
      if (this.hideScroll) {
        return this.scrollableColumns.length;
      }
      return Math.floor((this.parentWidth - this.fixedColumnsWidth - this.rowPadding.left - this.rowPadding.right)
        / (this.scrollableColMinWidth + this.colsGap));
    },
    // Все отображаемые на данный момент столбцы (и фиксированные, и нефиксированные)
    visibleColumns(): HeatMapColumn[] {
      const temp = this.scrollableColumns.slice(
        this.leftVisibleScrollableColIndex,
        this.leftVisibleScrollableColIndex + this.visibleScrollableColumnsCount,
      );

      return [...this.fixedColumns, ...temp];
    },
    hasScroll(): boolean {
      return !this.hideScroll && this.visibleScrollableColumnsCount < this.scrollableColumns.length;
    },
    isScrolledToEnd(): boolean {
      return this.leftVisibleScrollableColIndex + this.visibleScrollableColumnsCount >= this.scrollableColumns.length;
    },
  },

  watch: {
    parentWidth: {
      immediate: true,
      async handler() {
        if (this.parentWidth) {
          await this.$nextTick();
          this.$emit('ready');
        }
      },
    },
  },

  mounted() {
    this.parentWidth = this.$el.parentElement?.offsetWidth || 0;
    this.resizeObserver = new ResizeObserver((entries) => {
      if (!this.hideScroll) {
        this.parentWidth = entries[0].contentRect.width;
      }
    });

    this.resizeObserver.observe(this.$el.parentElement as Element);
  },

  beforeDestroy() {
    this.resizeObserver?.disconnect();
  },
});
