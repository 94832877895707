
import Vue from 'vue';
import NoDataStub from '@/components/common/NoDataStub.vue';

export default Vue.extend({
  name: 'NoFilteredDataStub',
  components: {
    NoDataStub,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    subtitleClass: {
      type: String,
      default: '',
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
});
