
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TsxassAbility, LicenseType } from '@/helpers/constants';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';
import SelectExpertsGroup from '@/components/analytics/widgets/SelectExpertsGroup.vue';
import { V1EntitiesAnalyticsFeedbackJohariItem, V1EntitiesAnalyticsFeedbackAverageRate } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import AverageRatingWidget from '@/components/analytics/widgets/AverageRatingWidget.vue';
import NpsHeatMap from '@/components/analytics/widgets/NpsHeatMap.vue';

type SelectedGroup = {
  id: number;
  name: string;
  withoutSelf: boolean;
};

export default Vue.extend({
  name: 'TabNps',

  components: {
    NoAnalyticsTabDataStub,
    SelectExpertsGroup,
    AverageRatingWidget,
    NpsHeatMap,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ratingGroupSelected: null as null | SelectedGroup,
      averageRatingData: null as null | V1EntitiesAnalyticsFeedbackAverageRate,
      loadingAverage: false,
      TsxassAbility,
      chartData: {
        values: [] as V1EntitiesAnalyticsFeedbackJohariItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
        },
      },
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('profile', {
      profile: 'profile',
      licenses: 'licenses',
    }),
    hasHideLicense(): Boolean {
      const { profile } = this;
      const hasHideLicense = this.licenses.some((name: string) => name === LicenseType.HIDE_EXPERT_GROUPS);
      const canEditSurvey = this.can(TsxassAbility.CAN_EDIT_ANY_SURVEY_360);
      return (hasHideLicense && !canEditSurvey) || !profile;
    },
    isTabEmpty(): boolean {
      // TODO: вернуть условие которое было убрано для тестов
      // return this.averageRatingData?.count === 0;
      return false;
    },
    key(): string {
      return `${this.surveyId}-${this.userId}`;
    },
  },
  watch: {
    key() {
      this.loadAverageRatingData();
    },
  },
  methods: {
    onRatingGroupSelect(group: SelectedGroup) {
      if (group === this.ratingGroupSelected) {
        return;
      }
      this.ratingGroupSelected = group;
      this.loadAverageRatingData();
    },
    async loadAverageRatingData() {
      try {
        this.loadingAverage = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackAverageRate(
          this.surveyId,
          this.ratingGroupSelected?.id || undefined,
          this.userId || undefined,
          this.ratingGroupSelected?.withoutSelf,
        );
        this.averageRatingData = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loadingAverage = false;
      }
    },
  },
});
