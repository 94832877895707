
import { defineComponent, PropType } from 'vue';

import NoDataStub from '@/components/common/NoDataStub.vue';
import ProgressWithPercentage from '@/components/analytics/charts/ProgressWithPercentage.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';

export default defineComponent({
  name: 'DevelopmentTasksTable',
  components: {
    ConfirmDialog,
    NoDataStub,
    ProgressWithPercentage,
  },

  props: {
    developmentTasks: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },

  data() {
    return {
      isMenuShowed: false,
      actions: ['delete', 'edit', 'progress'],
      warnedActions: ['delete'],
      menuItems: [
        {
          type: 'edit',
          icon: 'fal fa-edit',
          title: this.$t('edit'),
        },
        {
          type: 'progress',
          icon: 'fal fa-analytics',
          title: this.$t('analytics.developmentTasks.changeTaskProgress'),
        },
        {
          type: 'delete',
          icon: 'fal fa-trash-alt',
          title: this.$t('delete'),
        },
      ],
      headers: [
        {
          text: this.$t('analytics.developmentTasks.taskName'),
          value: 'name',
          sortable: false,
          width: '20%',
        },
        {
          text: this.$t('analytics.developmentTasks.taskQuestionName'),
          value: 'questionName',
          sortable: false,
          width: '20%',
        },
        {
          text: this.$t('analytics.developmentTasks.taskWeight'),
          value: 'weight',
          sortable: false,
          width: '5%',
        },
        {
          text: this.$t('analytics.developmentTasks.taskScheduledAt'),
          value: 'scheduledAt',
          sortable: false,
          width: '14%',
        },
        {
          text: this.$t('analytics.developmentTasks.taskFinishedAt'),
          value: 'finishedAt',
          sortable: false,
          width: '14%',
        },
        {
          text: this.$t('analytics.developmentTasks.taskProgressInTable'),
          value: 'progress',
          sortable: false,
          width: '12%',
        },
        {
          text: '',
          value: 'status',
          sortable: false,
        },
      ],
      tasks: [],
    };
  },

  computed: {
    menuActions() {
      return this.menuItems.filter((item) => this.actions.includes(item.type));
    },
  },

  methods: {
    async handleMenuItemClick(type: string, id: number) {
      if (type === 'delete') {
        const res = await this.$refs.modalConfirm.show();
        if (res) {
          this.$emit(type, id);
        }
        return;
      }
      this.$emit(type, id);
    },
    formattedDate(date: string): string {
      return date?.length > 0 ? this.$dayjs(date).format('DD.MM.YYYY') : '-';
    },
  },
});
