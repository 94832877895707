
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProgressWithPercentage',
  props: {
    value: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
  },
  computed: {
    isValueVisible() {
      return this.value || this.value === 0;
    },
    percentageValue() {
      return Number(this.value);
    },
  },
});
