
import Vue, { PropType } from 'vue';
import LineChart from '@/components/analytics/charts/LineChart.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { V1EntitiesAnalyticsFeedbackDestructorsDynamics } from '@/services/api/tsxass';
import { ChartDataset } from 'chart.js';
import { getLineChartColor } from '@/helpers/chartColors';

interface LegendSelectItem {
  id: number | null,
  name: string,
}

export default Vue.extend({
  name: 'DynamicsChartWidget',

  components: {
    LineChart,
    SectionTitle,
    TTLoader,
  },

  props: {
    chartData: {
      type: [Object, null] as PropType<V1EntitiesAnalyticsFeedbackDestructorsDynamics | null>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    allLegendsItemTitle: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedLegendId: null,
    };
  },

  computed: {
    xLabels(): string[] {
      return this.chartData
        ? this.chartData.axis.map((tick) => tick.name)
        : [];
    },
    legendList(): LegendSelectItem[] {
      return [
        { id: null, name: this.allLegendsItemTitle },
        ...(this.chartData?.legend || []),
      ];
    },
    dataSetsMap(): Record<number, ChartDataset> {
      if (!this.chartData) {
        return {};
      }

      const xValuesMap = Object.fromEntries(this.chartData.axis.map((item, index) => [item.id, index]));

      const entries = this.chartData.legend.map((legendItem, index) => {
        const data = this.chartData!.values
          .filter((tickValue) => tickValue.legendId === legendItem.id)
          .map((tickValue) => ({
            x: xValuesMap[tickValue.axisId],
            y: tickValue.value,
          }));

        const color = getLineChartColor(index);

        return [
          legendItem.id,
          {
            label: legendItem.name,
            data,
            borderColor: color,
            backgroundColor: color,
          },
        ];
      });

      return Object.fromEntries(entries);
    },
    selectedDataSets(): ChartDataset[] {
      return this.selectedLegendId === null
        ? Object.values(this.dataSetsMap)
        : [this.dataSetsMap[this.selectedLegendId]];
    },
  },
});
