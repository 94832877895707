
import Vue, { PropType } from 'vue';
import ResizeObserver from 'v-resize-observer';
import debounce from 'lodash/debounce';
import { ApportionmentChartValue } from './types';

type StyleObject = Record<string, string>;

export default Vue.extend({
  name: 'ApportionmentChart',

  directives: {
    // @ts-ignore
    resize: ResizeObserver.directive,
  },

  props: {
    values: {
      type: Array as PropType<ApportionmentChartValue[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hiddenLabelIds: [] as string[],
    };
  },

  computed: {
    nonZeroValues(): ApportionmentChartValue[] {
      return this.values.filter((item) => item.value > 0);
    },
    gridStyle(): StyleObject {
      return {
        gridTemplateColumns: this.nonZeroValues.map((item) => `${item.value}fr`).join(' '),
      };
    },
  },

  created() {
    this.onResize = debounce(this.onResize, 300);
  },

  methods: {
    onResize() {
      this.$nextTick(() => this.updateHiddenLabels());
    },
    getItemStyle(color: string) {
      return `backgroundColor:${color};`;
    },
    updateHiddenLabels() {
      const res = [] as string[];
      // Скрываем лэйблы, которые не влезают по ширине
      this.nonZeroValues.forEach((item) => {
        const ref = this.$refs[`label-${item.id}`];
        const element = (Array.isArray(ref) ? ref[0] : ref) as HTMLDivElement;
        if (!element) {
          return;
        }
        const width = element.clientWidth;
        const labelWidth = element.querySelector('span')?.offsetWidth || 0;
        if (labelWidth > width) {
          res.push(item.id);
        }
      });

      this.hiddenLabelIds = res;
    },
  },
});
