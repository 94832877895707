
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TTNumberInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newValue: 0,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.newValue = this.setValue(val);
      },
    },
    newValue: {
      handler(val) {
        this.newValue = this.setValue(val);
        this.$emit('input', this.newValue);
      },
    },
  },
  methods: {
    decrement() {
      this.newValue -= 1;
    },
    increment() {
      this.newValue += 1;
    },
    setValue(val: number) {
      if (this.max && val >= this.max) {
        return this.max;
        // eslint-disable-next-line
      } else if (this.min && val <= this.min) {
        return this.min;
      } else {
        return +val;
      }
    },
  },
});
