
import Vue from 'vue';
import { getScoreIconByValue, getNpsScaleScoreIconByValue } from '@/helpers/getScoreIcon';

export default Vue.extend({
  name: 'AverageRatingWidget',

  props: {
    averageRatingData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    anonimityReached(): boolean {
      const optionsAvg = this.averageRatingData?.optionsAvg;
      if (!optionsAvg) {
        return false;
      }
      return !optionsAvg.display && optionsAvg.anonymityThresholdReached;
    },
    avg(): string {
      if (this.anonimityReached) {
        return '—';
      }
      const min = this.averageRatingData?.settings.minValue;
      const avg = this.averageRatingData?.avg as number;
      return min === -100 ? avg.toFixed(0) : avg.toFixed(1);
    },
    bench(): string {
      const min = this.averageRatingData?.settings.minValue;
      const bench = this.averageRatingData?.bench as number;
      return min === -100 ? bench.toFixed(0) : bench.toFixed(1);
    },
    averageRatingLabelStyle(): Record<string, string> {
      if (this.anonimityReached) {
        return {
          backgroundColor: 'gray',
        };
      }
      const min = this.averageRatingData?.settings.minValue;
      const max = this.averageRatingData?.settings.maxValue;
      if (min === -100) {
        return {
          backgroundColor: getNpsScaleScoreIconByValue(this.averageRatingData?.avg as number).mutedColorCode,
        };
      }
      const backgroundColor = getScoreIconByValue(
        this.averageRatingData?.avg,
        min,
        max,
      ).mutedColorCode;

      return {
        backgroundColor,
      };
    },
  },
});
