import { Chart, Plugin } from 'chart.js';

interface JohariQuadrantsOptions {
  // Краевые значения графика
  bounds: {
    minX: number,
    maxX: number,
    minY: number,
    maxY: number,
  },
  // Отступ вокруг каждого квадранта
  margin: number,
  // Цвета квадрантов
  colors: {
    topLeft: string,
    topRight: string,
    bottomRight: string,
    bottomLeft: string,
  }
}

/**
 * Рисует квадранты для графика Окно Джохари
 */
export default {
  id: 'johariQuadrants',
  beforeDraw(chart: Chart, _args: any, options: JohariQuadrantsOptions) {
    const {
      ctx,
      scales: { x, y },
    } = chart;

    const {
      bounds: {
        minX,
        maxX,
        minY,
        maxY,
      },
      margin,
      colors,
    } = options;

    const left = x.getPixelForValue(minX);
    const midX = x.getPixelForValue((maxX - minX) / 2 + minX);
    const top = y.getPixelForValue(maxY);
    const midY = y.getPixelForValue((maxY - minY) / 2 + minY);

    const rectWidth = midX - left - margin * 2;
    const rectHeight = midY - top - margin * 2;

    ctx.save();
    ctx.fillStyle = colors.topLeft;
    ctx.fillRect(left + margin, top + margin, rectWidth, rectHeight);
    ctx.fillStyle = colors.topRight;
    ctx.fillRect(midX + margin, top + margin, rectWidth, rectHeight);
    ctx.fillStyle = colors.bottomRight;
    ctx.fillRect(midX + margin, midY + margin, rectWidth, rectHeight);
    ctx.fillStyle = colors.bottomLeft;
    ctx.fillRect(left + margin, midY + margin, rectWidth, rectHeight);
    ctx.restore();
  },
} as Plugin<'scatter', any>;
