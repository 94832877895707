
import Vue from 'vue';
import { getScoreIconByValue, getLargeScaleScoreIconByValue, getNpsScaleScoreIconByValue } from '@/helpers/getScoreIcon';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';
import { QuestionScaleAnswerIconData } from '@/constants/questionScaleAnswers';

export default Vue.extend({
  name: 'GradeValueSmile',

  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 5,
    },
    size: {
      type: Number,
      default: 13,
    },
    iconPrefix: {
      type: String,
      default: 'fas',
    },
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    has10ScaleLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_10_SCALE);
    },
    iconData(): QuestionScaleAnswerIconData {
      if (this.min === -100 && this.max === 100) {
        return getNpsScaleScoreIconByValue(this.value as number);
      }
      if (this.max > 9 && this.has10ScaleLicense) {
        const iconData = getLargeScaleScoreIconByValue(
          this.value as number,
          this.min, this.max,
          false,
          this.iconPrefix,
        );
        return {
          ...iconData,
          mutedColorCode: iconData.color,
        };
      }
      return getScoreIconByValue(this.value, this.min, this.max, false, this.iconPrefix);
    },
  },
});
