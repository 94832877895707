<template>
  <TTActionDialog
    v-model="isVisible"
    :width="width"
    :closable="hasClose"
    @input="onCloseClick"
  >
    <template #title>
      <p class="tt-text-body-2 tt-light-mono-46--text mb-2">
        {{ attentionText || $t('attention') }}
      </p>
      <p
        v-if="title"
        class="tt-text-subtitle tt-light-mono-100--text mb-0"
      >
        {{ title }}
      </p>
    </template>

    <template #default>
      <p class="tt-text-body-2 tt-light-mono-100--text mb-0">
        <slot>
          {{ text }}
        </slot>
      </p>
    </template>

    <template #actions>
      <TTBtn
        v-if="hasCancel"
        :text="plainCancel"
        :color="plainCancel ? 'tt-primary' : 'tt-secondary-danger'"
        large
        data-test-label="buttonCancel"
        @click="onCancel"
      >
        {{ cancelText || $t('cancelButton') }}
      </TTBtn>
      <TTBtn
        :color="alertConfirm ? 'tt-danger' : 'tt-primary'"
        large
        class="ml-5"
        data-test-label="buttonConfirm"
        @click="onConfirm"
      >
        {{ confirmText || $t('continue') }}
      </TTBtn>
    </template>
  </TTActionDialog>
</template>

<script>
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';

export default {
  name: 'ConfirmDialog',

  components: {
    TTActionDialog,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    attentionText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 396,
    },
    plainCancel: {
      type: Boolean,
      default: true,
    },
    cancelOnClose: {
      type: Boolean,
      default: false,
    },
    alertConfirm: {
      type: Boolean,
      default: false,
    },
    hasCancel: {
      type: Boolean,
      default: true,
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
    payload: undefined,
  },

  data() {
    return {
      isVisible: false,
      promise: null,
      resolve: null,
    };
  },
  methods: {
    onCancel() {
      if (this.resolve) {
        this.resolve(false);
        this.isVisible = false;
      }
    },
    onConfirm() {
      if (this.resolve) {
        const payload = this.payload === undefined ? true : this.payload;
        this.resolve(payload);
        this.isVisible = false;
      }
    },
    onCloseClick() {
      if (this.cancelOnClose) {
        this.onCancel();
      }
    },

    /**
     * Публичный метод
     * Открывает диалог и возвращает промис с результатом выбора пользователя (false - "Cancel", true - "Confirm")
     */
    show() {
      this.promise = new Promise((resolve) => {
        this.resolve = resolve;
      });

      this.isVisible = true;
      return this.promise;
    },
  },
};
</script>
