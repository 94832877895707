
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'TTExpansionItem',

  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, null] as PropType<boolean | null>,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // индекс открытой панели
      internalValue: 0 as number | null,
    };
  },

  computed: {
    // Внутренее значение модели типа boolean
    internalModelValue: {
      get(): boolean {
        return this.internalValue === 0;
      },
      set(newValue: boolean) {
        this.internalValue = newValue ? 0 : null;
      },
    },
  },

  watch: {
    internalModelValue(newInternalModelValue) {
      if (this.value !== newInternalModelValue) {
        this.$emit('input', newInternalModelValue);
      }
    },
    value(newModelValue) {
      if (this.internalModelValue !== newModelValue) {
        this.internalModelValue = newModelValue;
      }
    },
  },

  created() {
    this.internalModelValue = this.value !== null ? this.value : this.initialValue;
  },
});
