
import Vue, { PropType } from 'vue';
import HeatMap from '@/components/analytics/charts/HeatMap/HeatMap.vue';
import { HeatMapColumn, HeatMapRow, HeatMapRow as HeatMapRowType } from '@/components/analytics/charts/HeatMap/types';
import { V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup } from '@/services/api/tsxass';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import i18n from '@/plugins/vue-i18n';
import { CompetencyHeatMapItem, ChartDataType } from './types';

const getGroupColumnName = (groupId: number) => `group-${groupId}`;

export default Vue.extend({
  name: 'CompetenciesHeatMap',

  components: {
    HeatMap,
    GradeValueSmile,
    SectionTitle,
  },

  props: {
    fixedColumns: {
      type: Array as PropType<HeatMapColumn[]>,
      default: () => [],
    },
    chartData: {
      type: Object as PropType<ChartDataType>,
      default(): ChartDataType {
        return {
          groups: [] as V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup[],
          items: [] as CompetencyHeatMapItem[],
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default(): string {
        return this.$t('analytics.heatMap') as string;
      },
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    chartDataIsNotEmpty(): boolean {
      return !!this.chartData.items?.length;
    },
    rows(): HeatMapRowType[] {
      const preparedRows = this.chartData.items.map((item) => ({
        ...item,
        ...Object.fromEntries(
          item.values.map(({ groupId, value, options }) => [
            getGroupColumnName(groupId),
            this.getGroupColumnValue(value, options),
          ]),
        ),
      }));

      const itemsMap: Record<string | number, HeatMapRowType> = Object.fromEntries(
        preparedRows.map((item) => [item.id, { ...item, children: [] }]),
      );
      const rootItems: HeatMapRowType[] = [];

      Object.values(itemsMap).forEach((item) => {
        if (item.parentId) {
          itemsMap[item.parentId].children!.push(item);
        } else {
          rootItems.push(item);
        }
      });
      rootItems.sort((a, b) => a.sortIndex - b.sortIndex);
      rootItems.forEach((rootItem) => rootItem.children?.sort((a, b) => a.sortIndex - b.sortIndex));
      return rootItems;
    },
    groupsCols() {
      if (!this.chartData) {
        return [];
      }
      // @ts-ignore
      return this.chartData.groups.map((group) => ({
        key: getGroupColumnName(group.id),
        title: group.name,
        hint: group.name,
        isFixed: false,
        comment: true,
        align: 'center',
      }));
    },
    columns(): HeatMapColumn[] {
      if (!this.chartData) {
        return [];
      }
      // @ts-ignore
      return [...this.fixedColumns, ...this.groupsCols];
    },
  },
  methods: {
    averageIsEnabled(item: HeatMapRow) {
      return !item.averageOptions || (item.averageOptions.display && !item.averageOptions.anonymityThresholdReached);
    },
    getGroupColumnValue(value, options) {
      if (options && !options.display) {
        return i18n.t('analytics.baseCompetencies.heatMap.anonymityThresholdReached');
      }
      if (typeof value === 'number') {
        return this.minRating === -100 ? Number(value.toFixed(0)) : Number(value.toFixed(1));
      }
      return null;
    },
  },
});
