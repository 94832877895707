
import Vue from 'vue';
import NoDataStub from '@/components/common/NoDataStub.vue';

export default Vue.extend({
  name: 'NoAnalyticsTabDataStub',

  components: {
    NoDataStub,
  },

  inject: ['RouteNames'],

  computed: {
    isShowButton() {
      return Boolean(this.$route.query?.userId);
    },
  },

  methods: {
    onButtonAction() {
      this.$router.push({ name: this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_PIVOT_TABLE });
    },
  },
});
