
import Vue, { PropType } from 'vue';
import {
  ChartConfiguration, Chart, registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import JohariWindowQuadrantsPlugin from '@/utils/charts/plugins/JohariWindowQuadrantsPlugin';
import JohariWindowGridlinesPlugin from '@/utils/charts/plugins/JohariWindowGridlinesPlugin';
import AxisArrowsPlugin from '@/utils/charts/plugins/AxisArrowsPlugin';
import TTLoader from '@/components/ui/TTLoader.vue';
import getJohariMiddleValue from '@/helpers/getJohariMiddleValue';
import {
  JohariChartDataSetData,
  JohariPoint,
  JohariColorSettings,
} from './types';

interface PreparedValuesMap {
  x: Record<number, number>,
  y: Record<number, number>,
}

const DEFAULT_COLOR_SETTINGS: JohariColorSettings = {
  topLeft: {
    background: '#FFF6DC',
    point: '#FFC700',
  },
  topRight: {
    background: '#D4F8E7',
    point: '#00D358',
  },
  bottomLeft: {
    background: '#FFE2E2',
    point: '#FF2B3E',
  },
  bottomRight: {
    background: '#FFE2E2',
    point: '#FF2B3E',
  },
};

Chart.register(...registerables);

/**
 * Компонент графика Окно Джохари.
 * Представляет из себя график с четырьмя квадрантами и точками внутри них.
 * Квадранты равны по площади и размерам.
 * Но первые два (слева) могут содержать в себе другое количество целых значений шкалы, чем вторые два (справа).
 * Например: первым квадрантам соответствуют значения x от 1 до 3, а вторым - от 4 до 5, при этом визуально
 * квадранты остаются одинакового размера, изменяется шкала x -
 * длины отрезков между значениями становятся неравномерными). Аналогично для шкалы y.
 * ChartJS умеет выводить "тики" только с одинаковым расстоянием между друг другом,
 * поэтому реализовано следующее решение:
 * - пользователь передает в пропсах границы квадрантов (min и max) и массив значений точек
 * - на основе min и max вычисляется "середина" графика - граница первого квадранта
 * - строится "карта" preparedValuesMap с "помещенными" внутрь квадрантов значениями тиков.
 *   Ключ - исходное значение тика (например - 3), значение -
 *   координата на шкале, на которой тик должен выводиться (2.6), чтобы он был вписан в квадрант
 * - значения из preparedValuesMap используется для построения списка датасетов -
 *   подставляется вместо исходных координат точек (preparedDataSets).
 */
export default Vue.extend({
  name: 'JohariWindow',

  components: {
    TTLoader,
  },

  props: {
    colorSettings: {
      type: [Object, null] as PropType<JohariColorSettings>,
      default: () => ({ ...DEFAULT_COLOR_SETTINGS }),
    },
    points: {
      type: Array as PropType<JohariPoint[]>,
      default: () => [],
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 5,
    },
    yScaleTitle: {
      type: String,
      default: '',
    },
    xScaleTitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: null as Chart<'scatter', JohariChartDataSetData[]> | null,
      // Размер единичного отрезка в пикселях для каждой оси
      chartBasisSizeInPixels: {
        x: 0,
        y: 0,
      },
    };
  },

  computed: {
    // Максимальное значение первого квадранта
    middleValue(): number {
      return getJohariMiddleValue(this.min, this.max);
    },
    preparedValuesMap(): PreparedValuesMap {
      // Мин. отступы внутри квадрантов
      const minPaddingX = this.chartBasisSizeInPixels.x * 24;
      const minPaddingY = this.chartBasisSizeInPixels.y * 24;

      const spreadNumbersInRange = (min: number, max: number, range: [number, number], axis: 'x' | 'y') => {
        const marksCount = max - min + 1;
        const rangeWidth = range[1] - range[0];
        const padding = axis === 'x'
          ? Math.max(rangeWidth / (marksCount + 1.6), minPaddingX)
          : Math.max(rangeWidth / (marksCount + 1.001), minPaddingY);
        const stepSize = (rangeWidth - padding * 2) / (marksCount - 1.001);

        const res = Array.from({ length: marksCount }, (_, i) => min + i);
        return res.map((point, index) => ([
          point,
          (range[0] + padding + index * stepSize).toFixed(1),
        ]));
      };

      const mid = this.min + (this.max - this.min) / 2;

      const calcValuesForAxis = (axis: 'x' | 'y') => {
        const firstQuadrantValues = spreadNumbersInRange(
          this.min,
          this.middleValue,
          [this.min, mid],
          axis,
        );

        const secondQuadrantValues = spreadNumbersInRange(
          this.middleValue + 1,
          this.max,
          [mid, this.max],
          axis,
        );

        return Object.fromEntries([...firstQuadrantValues, ...secondQuadrantValues]);
      };

      return {
        x: calcValuesForAxis('x'),
        y: calcValuesForAxis('y'),
      };
    },
    preparedDataSets(): any {
      const getQuadrantPoints = (minX: number, maxX: number, minY: number, maxY: number) => {
        const filteredPoints = this.points.filter((point) => point.x >= minX
          && point.x <= maxX
          && point.y >= minY
          && point.y <= maxY);

        return filteredPoints.map((point) => ({
          ...point,
          x: this.preparedValuesMap.x[point.x],
          y: this.preparedValuesMap.y[point.y],
        }));
      };

      return [
        {
          label: 'topLeft',
          data: getQuadrantPoints(this.min, this.middleValue,
            this.middleValue + 1, this.max),
          borderColor: this.colorSettings.topLeft.point,
          backgroundColor: this.colorSettings.topLeft.point,
        },
        {
          label: 'bottomLeft',
          data: getQuadrantPoints(this.min, this.middleValue,
            this.min, this.middleValue),
          borderColor: this.colorSettings.bottomLeft.point,
          backgroundColor: this.colorSettings.bottomLeft.point,
        },
        {
          label: 'topRight',
          data: getQuadrantPoints(this.middleValue + 1, this.max,
            this.middleValue + 1, this.max),
          borderColor: this.colorSettings.topRight.point,
          backgroundColor: this.colorSettings.topRight.point,
        },
        {
          label: 'bottomRight',
          data: getQuadrantPoints(this.middleValue + 1, this.max,
            this.min, this.middleValue),
          borderColor: this.colorSettings.bottomRight.point,
          backgroundColor: this.colorSettings.bottomRight.point,
        },
      ];
    },
    chartSettings(): ChartConfiguration<'scatter', JohariChartDataSetData[]> {
      const bounds = {
        minX: this.min,
        maxX: this.max,
        minY: this.min,
        maxY: this.max,
      };

      return {
        type: 'scatter',
        data: {
          datasets: this.preparedDataSets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              position: 'left',
              suggestedMin: this.min,
              suggestedMax: this.max,
              min: this.min,
              max: this.max,
              title: {
                display: true,
                text: this.yScaleTitle,
                padding: {
                  bottom: 12,
                },
              },
              ticks: {
                display: false,
                sampleSize: 0,
              },
              grid: {
                drawOnChartArea: false,
                tickLength: 0,
              },
            },
            x: {
              suggestedMin: this.min,
              suggestedMax: this.max,
              min: this.min,
              max: this.max,
              grid: {
                drawOnChartArea: false,
                tickLength: 0,
              },
              title: {
                display: true,
                text: this.xScaleTitle,
                padding: {
                  top: 12,
                },
              },
              ticks: {
                display: false,
              },
            },
          },
          elements: {
            point: {
              radius: 12,
              pointStyle: 'rectRounded',
              hoverRadius: 16,
            },
            line: {
              borderWidth: 3,
            },
          },
          plugins: {
            // @ts-ignore
            johariQuadrants: {
              bounds,
              margin: 8,
              colors: {
                topLeft: this.colorSettings.topLeft.background,
                topRight: this.colorSettings.topRight.background,
                bottomRight: this.colorSettings.bottomRight.background,
                bottomLeft: this.colorSettings.bottomLeft.background,
              },
            },
            johariGridlines: {
              bounds,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              font: {
                family: 'Roboto',
                size: 12,
                weight: 500,
                lineHeight: 1,
              },
              formatter: (value) => value.title,
              offset: 2,
              padding: {
                top: 8,
                right: 7,
                bottom: 4,
                left: 7,
              },
              listeners: {
                click: (context) => {
                  this.$emit('point-click', context.dataset.data[context.dataIndex]);
                },
              },
            },
          },
          onResize: () => {
            this.updateChartBasisSize();
          },
        },
        plugins: [
          ChartDataLabels,
          JohariWindowQuadrantsPlugin,
          JohariWindowGridlinesPlugin,
          AxisArrowsPlugin,
        ],
      };
    },
  },

  watch: {
    chartSettings: {
      handler(val) {
        if (!this.chart) {
          return;
        }

        this.chart.data = val.data;
        this.chart.options = val.options;

        this.chart.update();
      },
    },
  },

  mounted() {
    this.chart = new Chart(this.$refs.canvasEl as HTMLCanvasElement, this.chartSettings);
    this.updateChartBasisSize();
  },

  beforeDestroy() {
    this.chart?.destroy();
  },

  methods: {
    updateChartBasisSize() {
      const calcForAxis = (scale: string) => {
        if (!this.chart?.scales[scale]) {
          return 0;
        }

        const a = this.chart?.scales[scale].getValueForPixel(1) || 0;
        const b = this.chart?.scales[scale].getValueForPixel(0) || 0;

        return Math.abs(b - a);
      };

      this.chartBasisSizeInPixels = {
        x: calcForAxis('x'),
        y: calcForAxis('y'),
      };
    },
  },
});
